import { ComponentProps } from 'react';
import styled from 'styled-components';

import { Box } from 'components/Box/Box';
import { colors, transition } from 'theme/theme';

type Props = ComponentProps<typeof Box> & {
  withLinks?: boolean;
  large?: boolean;
};

export const CmsThreeStepCtaBlockStepBox = styled(
  ({ withLinks, large, ...props }: Props) => {
    if (withLinks && !large) {
      return (
        <Box
          padding={[24, null, null, 48]}
          width={1}
          height="100%"
          borderRadius={12}
          bg="white"
          boxShadow="0 0 20px rgba(0,0,0,0.1)"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      );
    }

    return (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Box width={1} {...props} />
    );
  },
)<{ $url?: string | null }>`
  ${(props) =>
    props.withLinks &&
    props.$url &&
    !props.large &&
    `
      color: ${colors.mediumContentGrey};
      transition: all ${transition};
      cursor: pointer;
      a {
        color: inherit;
        text-decoration: none;
        transition: all ${transition};
        &:hover {
          text-decoration: none;
        }
      }
      &:hover {
        box-shadow: 0 0 6px rgba(0,0,0,0.2);
        a {
          color: ${colors.brandBlue};
        }
      }
    `}
`;
